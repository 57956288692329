import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Flex, Box } from 'rebass';
import { Head1, Head2, Head3, Text } from './Text';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { StaticQuery, graphql } from 'gatsby';
import { CardContainer, Card } from './Card';
import { themeGet } from '@styled-system/theme-get';
import { PositionBox, Container } from './primitives';
import { Alarm } from './Icons';

export const getColor = colorKey => themeGet(`colors.${colorKey}`);

const MEDIUM_CDN = 'https://cdn-images-1.medium.com/max/400';
const MEDIUM_URL = 'https://medium.com';

const CoverImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin-bottom: 0;
`;

const EllipsisHeading = styled(Head3).attrs({
  textTransform: 'lowercase',
  fontSize: 3,
})`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const PostBodyTagBox = styled(Box)`
  display: inline-block;

  border-radius: 4px;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;

  position: absolute;

  background: linear-gradient(
    180deg,
    ${getColor('brand-primary')} 0,
    ${getColor('brand-primary-offset')} 100%
  );
`;

const PostTag = ({ tagBody }) => (
  <PostBodyTagBox>
    <Head3 fontSize={2} p={3} color="white">
      {tagBody}
    </Head3>
  </PostBodyTagBox>
);

PostTag.propTypes = {
  tagBody: PropTypes.string.isRequired,
};

const Post = ({ title, text, image, url, date, time }) => (
  <Card
    onClick={() => window.open(url, '_blank')}
    border={2}
    borderColor="greyscale-light"
  >
    <Box>
      <PositionBox position="relative" top={5} left={-2}>
        <PostTag tagBody={'Medium'} />
      </PositionBox>
      {image && <CoverImage src={image} height="200px" alt={title} />}
      <Box p={4}>
        <EllipsisHeading>{title}</EllipsisHeading>
        <Flex color="greyscale-dark">
          <Alarm alignSelf="center" mr={2} />
          <Text py={0} fontSize={2}>
            {date}
          </Text>
        </Flex>
        <Text color="greyscale-darker" mt={1} lineheight={2}>
          {text}
        </Text>
      </Box>
    </Box>
  </Card>
);

Post.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
};

const parsePost = (author, defaultImage) => postFromGraphql => {
  const { id, uniqueSlug, createdAt, title, virtuals } = postFromGraphql;
  const image = virtuals.previewImage.imageId
    ? `${MEDIUM_CDN}/${virtuals.previewImage.imageId}`
    : defaultImage.childImageSharp.fixed.src;

  return {
    id,
    title,
    time: virtuals.readingTime,
    date: createdAt,
    text: virtuals.subtitle,
    image,
    url: `${MEDIUM_URL}/${author.username}/${uniqueSlug}`,
    Component: Post,
  };
};

const edgeToArray = data => data.edges.map(edge => edge.node);

const FeaturedWriting = () => (
  <StaticQuery
    query={graphql`
      query MediumPostQuery {
        site {
          siteMetadata {
            isMediumUserDefined
          }
        }
        featuredPostImage: file(absolutePath: { regex: "/business-cat.png/" }) {
          childImageSharp {
            fixed(width: 480) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        allMediumPost(limit: 7, sort: { fields: createdAt, order: DESC }) {
          totalCount
          edges {
            node {
              id
              uniqueSlug
              title
              createdAt(formatString: "MMM YYYY")
              virtuals {
                subtitle
                readingTime
                previewImage {
                  imageId
                }
              }
            }
          }
        }
        author: mediumUser {
          username
          name
        }
      }
    `}
    render={({ allMediumPost, site, author, featuredPostImage }) => {
      const posts = edgeToArray(allMediumPost).map(
        parsePost(author, featuredPostImage)
      );

      const { isMediumUserDefined } = site.siteMetadata;

      return (
        isMediumUserDefined && (
          <Box mb={[4, 5]}>
            <CardContainer minWidth="360px">
              {posts.map(({ Component, ...rest }) => (
                <Fade right key={rest.id}>
                  <Component {...rest} key={rest.id} />
                </Fade>
              ))}
            </CardContainer>
          </Box>
        )
      );
    }}
  />
);

const PostItem = ({ node }) => {
  const title = node.frontmatter.title || node.fields.slug;
  return (
    <Box mb={3} key={node.fields.slug}>
      <Head3 pb={0} color="brand-secondary">
        <Link
          style={{
            boxShadow: 'none',
          }}
          to={node.fields.slug}
        >
          {title}
        </Link>
      </Head3>
      <small>
        <Text py={1}>{node.frontmatter.date}</Text>
      </small>
      <Text py={1} mb={3} dangerouslySetInnerHTML={{ __html: node.excerpt }} />
    </Box>
  );
};

const PostList = ({ posts }) => (
  <Flex alignItems="center" mx="auto" flexDirection="column">
    {posts.map(({ node }) => (
      <PostItem node={node} />
    ))}
  </Flex>
);

const Writing = ({ posts }) => (
  <Flex flexDirection="row" justifyContent="center" alignItems="center">
    <Container>
      <Head1 my={[4]}>Blog</Head1>
      <FeaturedWriting />
      <Head2 my={[4]}>Tips and Tutorials</Head2>
      <PostList posts={posts} />
    </Container>
  </Flex>
);

export default Writing;
